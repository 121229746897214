import React, { Component } from 'react';
import NavbarThree from '../components/Layouts/NavbarTwo';
import SEO from "../components/Seo"
import Blogimg from "../assets/images/gallery.png"
import Footer from '../components/Layouts/Footer';
import BlogLisiting from '../components/Blog/BlogListing';

class Gallery extends Component {
    render() {
        return (
            <React.Fragment>
            <SEO title={"Credai Warangal Gallery"}  keywords={"Gallery"} description={"CREDAI Warangal was established in 2000 and since then has been in operation for the promotion of land sector and for the advantage of their members. "}  />
                <NavbarThree />
                <div className="page-title-area" style={{
       backgroundImage:"url("+ Blogimg +")"}} >
            
             <div className="container">
                    <div className="page-title-content">
                       
                        
                    </div>
                </div>
          
               </div>
                <section className="blog-area ptb-100">
        <div className="container">
          <div className="row">
          <div className="col">
               <div className="row">
                
             <BlogLisiting/>
                </div>
                      
                      </div>
          
          
            </div>
            </div>
</section>
                <Footer />
            </React.Fragment>
        );
    }
}
export default Gallery;
